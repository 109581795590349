import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import { deleteRecord, updateRecord } from "../api/genericEndpoints";
import { axiosInstance, baseAxiosConfig } from "../api/shared";
import {
  Comment,
  CommentInput,
  CommentSub,
  CommentSubInput,
} from "../schemas/comment";
import {
  PagedResults,
  parseSearchParamsToFilters,
  Query,
  Sort,
} from "../schemas/core";
import {
  SearchFilterConjunctParam,
  SearchFilterParam,
} from "../search/searchutils";

const CMT_KEY = "comment";
const CMT_SUB_KEY = "cmt-subs";
const CMT_SUB_CHECK_KEY = "check-sub";

export function useComment(accessToken: string, uuid?: string) {
  const getCmt = async (
    accessToken: string,
    uuid: string,
  ): Promise<Comment> => {
    const resp = await axiosInstance.get(
      `/comments/${uuid}`,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };
  return useQuery(
    [CMT_KEY, uuid],
    async () => (accessToken && uuid ? await getCmt(accessToken, uuid) : null),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useCommentsByRecord(accessToken: string, recordId?: string) {
  const getCmtsByRecord = async (
    accessToken: string,
    recordId: string,
  ): Promise<Comment[]> => {
    const resp = await axiosInstance.get(
      `/comments/by-record-id/${recordId}`,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };
  return useQuery(
    [CMT_KEY, recordId],
    async () =>
      recordId ? await getCmtsByRecord(accessToken, recordId) : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useQueryComments(
  accessToken: string,
  pageNum: number,
  pageSize: number,
  sorts?: Sort[],
  filters?: (SearchFilterParam | SearchFilterConjunctParam)[],
) {
  const query: Query = {
    sorts: sorts || [],
    filters: parseSearchParamsToFilters(filters) || [],
  };
  const queryCmts = async (): Promise<PagedResults<Comment>> => {
    const resp = await axiosInstance.post("/comments/query/", query, {
      ...baseAxiosConfig(accessToken),
      params: { pageNum, pageSize },
    });
    return resp.data;
  };
  return useQuery(
    [CMT_KEY, "query", pageNum, pageSize, sorts, filters],
    async () => await queryCmts(),
    { staleTime: RQUERY_STALE_TIME, keepPreviousData: true },
  );
}

export function useUpdateComment() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: CommentInput;
    }) => {
      return updateRecord(accessToken, inputData, "comments");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([CMT_KEY]);
      },
    },
  );
}

export function useDeleteComment() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return deleteRecord(accessToken, uuid, "comments");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([CMT_KEY]);
      },
    },
  );
}

export function useCmtSubsByRecord(accessToken: string, recordId?: string) {
  const getCmtSubsByRecord = async (
    accessToken: string,
    recordId: string,
  ): Promise<PagedResults<CommentSub>> => {
    const query: Query = {
      sorts: [{ field: "subscribeDt", direction: "desc" }],
      filters: [{ field: "recordId", op: "=", term: recordId }],
    };
    const resp = await axiosInstance.post(
      `/comments/subs/query`,
      query,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };
  return useQuery(
    [CMT_SUB_KEY, recordId],
    async () =>
      recordId ? await getCmtSubsByRecord(accessToken, recordId) : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useAddCmtSub() {
  const addSub = async (
    accessToken: string,
    inputData: CommentSubInput,
  ): Promise<string> => {
    const resp = await axiosInstance.post(
      "/comments/subs/add",
      {
        email: inputData.email,
        recordId: inputData.recordId,
      },
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };

  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: CommentSubInput;
    }) => {
      return addSub(accessToken, inputData);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([CMT_SUB_KEY]);
      },
    },
  );
}

export function useDeleteCmtSub() {
  const deleteSub = async (accessToken: string, uuid: string) => {
    const resp = await axiosInstance.delete(
      `/comments/subs/${uuid}`,
      baseAxiosConfig(accessToken),
    );
    return resp.status === 204;
  };
  const queryClient = useQueryClient();
  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return deleteSub(accessToken, uuid);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([CMT_SUB_KEY]);
      },
    },
  );
}

export function useRequestCmtSub() {
  const requestCmtSub = async (
    accessToken: string,
    inputData: CommentSubInput,
  ) => {
    const resp = await axiosInstance.post(
      "/comments/subs/request-subscribe",
      {
        email: inputData.email,
        recordId: inputData.recordId,
        domain: inputData.domain,
      },
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: CommentSubInput;
    }) => {
      return requestCmtSub(accessToken, inputData);
    },
  );
}

export function useRequestCmtUnsub() {
  const requestCmtUnsub = async (
    accessToken: string,
    inputData: CommentSubInput,
  ) => {
    const resp = await axiosInstance.put(
      "/comments/subs/request-unsubscribe",
      {
        email: inputData.email,
        recordId: inputData.recordId,
        domain: inputData.domain,
      },
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: CommentSubInput;
    }) => {
      return requestCmtUnsub(accessToken, inputData);
    },
  );
}

export function useCheckCmtSub(
  accessToken: string,
  recordId: string,
  email: string,
) {
  const checkCmtSub = async (): Promise<boolean> => {
    const resp = await axiosInstance.get("/comments/subs/check", {
      ...baseAxiosConfig(accessToken),
      params: { recordId, email },
    });
    return resp.data;
  };
  return useQuery(
    [CMT_SUB_KEY, CMT_SUB_CHECK_KEY, recordId],
    async () => await checkCmtSub(),
    {
      staleTime: RQUERY_STALE_TIME,
    },
  );
}

export function useSubscribe(subToken?: string | null) {
  const queryClient = useQueryClient();

  const subscribe = async (): Promise<string> => {
    const resp = await axiosInstance.post(
      "/comments/subs/subscribe",
      undefined,
      { params: { subToken } },
    );
    return resp.status === 200
      ? resp.data
      : "Something went wrong with your subscription.";
  };
  return useQuery(
    ["sub-token", subToken],
    async () => {
      if (subToken) {
        return subscribe();
      }
    },
    { onSuccess: () => queryClient.invalidateQueries([CMT_SUB_KEY]) },
  );
}

export function useUnsubscribe(unsubToken?: string | null) {
  const queryClient = useQueryClient();

  const unsubscribe = async (): Promise<string> => {
    const resp = await axiosInstance.put(
      "/comments/subs/unsubscribe",
      undefined,
      { params: { unsubToken } },
    );
    return resp.status === 200
      ? resp.data
      : "Something went wrong with your subscription.";
  };
  return useQuery(
    ["sub-token", unsubToken],
    async () => {
      if (unsubToken) {
        return unsubscribe();
      }
    },
    { onSuccess: () => queryClient.invalidateQueries([CMT_SUB_KEY]) },
  );
}
